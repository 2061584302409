<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 16.199 16.199"
  >
    <path
      :style="iconStyle"
      d="M9.258,1.167a8.1,8.1,0,1,0,8.108,8.1A8.1,8.1,0,0,0,9.258,1.167Zm.008,14.579a6.48,6.48,0,1,1,6.48-6.48A6.478,6.478,0,0,1,9.266,15.746Zm.4-10.529H8.456v4.86l4.252,2.551.607-1L9.671,9.469Z"
      transform="translate(-1.167 -1.167)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '16.199',
    },
    width: {
      type: String,
      default: '16.199',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
